import GameModel from '../models/gameModel';
import XXXDataParser from './XXXDataParser';
import eServerActionTypes from '../enums/eServerActionTypes';
import { getBonusPurchaseCardsConfig } from '../enums/eBonusPurchaseCards';

export default new class ServerManager {
  constructor() {
    this._initManagerParams = {
      developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=juicycrush&no_redirect&no_op_launcher',
      cheatsParams:  ['forceWilds', 'force_reels'],
      baseData: {
        ver: '1.0'
      }
    };
  }

  async onInit() {
    window.OPWrapperService.serverManager.initManagerGameRequests(this._initManagerParams);
    await window.OPWrapperService.serverManager.sendGameInitRequest();
    const data = await this._sendRequest({}, 'config');
    GameModel.setNewData(XXXDataParser.parseInit(data));
  }

  async onStartSpin() {
    let request = {
      betIndex: GameModel.betIndex,
    };
    const data = await this._sendRequest(request, eServerActionTypes.ESAT_SPIN);
    GameModel.setNewData(XXXDataParser.parseSpin(data));
    return data;
  }

  async useBomb() {
    let request = {
      betIndex: GameModel.betIndex,
    };
    const data = await this._sendRequest(request, eServerActionTypes.ESAT_USE_BOMB);
    const parsedData = XXXDataParser.parseFeature(data);
    GameModel.setNewData(parsedData);
    return data;
  }

  async buyBonus() {
    let request = {
      betIndex: GameModel.betIndex,
      type: GameModel.bonusPurchaseData.type,
    };
    const data = await this._sendRequest(request, eServerActionTypes.ESAT_BUY);
    if (GameModel.bonusPurchaseData.type === getBonusPurchaseCardsConfig().EBPT_FREE_SPINS.type) {
      OPWrapperService.ControllerStatistic.increaseBuyBonusCount(GameModel.betIndex);
    }
    const parsedData = XXXDataParser.parsePurchase(data);
    GameModel.setNewData(parsedData);
    return data;
  }

  async _sendRequest(data, id, urlPath = 'game_request') {
    data.id = id;
    return await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
  }
}
