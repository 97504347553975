import BaseState from 'Engine/base/states/BaseState';
import GameModel from '../models/gameModel';
import ServerManager from '../api/ServerManager';

export default class BuyBonusState extends BaseState {
  async start() {
    super.start();
    OPWrapperService.ControllerStatistic.bet = GameModel.bonusPurchaseData.price;
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    await ServerManager.buyBonus();
    this.complete();
  }

  complete() {
    GameModel.bonusPurchaseData = null;
    super.complete();
  }
}
